/**
 * This file contains system wide configs
 *
 * @since 0.1.0
 * @version 0.1.0
 */
import React from 'react';
import map from 'lodash/map';
import Cookies from 'js-cookie';

export const ConfigsContext = React.createContext();

export const LocaleContext = React.createContext();

export const Component = React.createContext();

export const DATE_FORMAT = 'dd-MM-yyyy';

export const TIME_FORMAT = 'hh:mm a';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm a';

export const SCHEDULE_SMS_DATE_FORMAT = 'yyyy-M-dd hh:mm a';

export const THEME = '';

export const TITLE_OPTIONS = [
  { value: 'Mr.', label: 'Mr. ' },
  { value: 'Mrs.', label: 'Mrs. ' },
  { value: 'Ms.', label: 'Ms. ' },
];

export const EMAIL_ALERT_DURATION = [
  { value: '3', label: '3 Mins' },
  { value: '5', label: '5 Mins' },
  { value: '10', label: '10 Mins' },
  { value: '15', label: '15 Mins' },
  { value: '20', label: '20 Mins' },
  { value: '30', label: '30 Mins ' },
  { value: '60', label: '1 Hour' },
  { value: '90', label: '1.5 Hours' },
  { value: '120', label: '2 Hours' },
  { value: '150', label: '2.5 Hours' },
  { value: '180', label: '3 Hours' },
];

export const GENDER = {
  male: 'Male',
  female: 'Female',
};

export const VENDOR_TYPES = {
  active: 'Online',
  inactive: 'Offline',
};

export const PAYMENT_METHOD_TYPES = {
  collections: 'Collections',
  push: 'Checkout',
};

export const VENDOR_SMS_REMINDER = {
  0: 'Inactive',
  1: 'Active',
};
export const VENDOR_STATUSES = {
  inactive: 'Offline',
  active: 'Online',
};

export const BOT_STATUSES = {
  active: 'active',
  inactive: 'inactive',
};

export const PAYSTACK_CURRENCY = {
  NGN: 'NGN',
  GHS: 'GHS',
  ZAR: 'ZAR',
  USD: 'USD',
};

export const DPO_CURRENCY = {
  TZS: 'TZS',
  UGX: 'UGX',
  ZMW: 'ZMW',
  KES: 'KES',
  USD: 'USD',
};

export const DPO_METHODS = [
  {
    value: 'Push',
    label: 'Push',
  },
  {
    value: 'Redirect',
    label: 'Redirect',
  },
];

export const STATUS_OPTIONS = {
  inactive: 'Offline',
  active: 'Active',
};
export const API_TYPES = {
  1: 'Active',
  0: 'Inactive',
};
export const USER_ROLES = {
  vendor: 'Vendor',
  admin: 'Admin',
  reseller: 'Reseller',
  subaccount: 'Sub-Account',
  accounts: 'Admin Accounts',
  technical: 'Admin Technical',
  sales: 'Admin Sales',
  support: 'Admin Support',
  senderid: 'Admin Sender Id',
  agent: 'agent',
};
export const VENDOR_ACCOUNT_STATUSES = VENDOR_SMS_REMINDER;

/* used in sender names */
export const STATUSES = {
  pending: 'Pending',
  active: 'Active',
  inactive: 'InActive',
};

/* used in bot transactions */
export const CHANNELS = {
  whatsapp: 'whatsapp',
  facebook: 'facebook',
  sms: 'sms',
};

export const CHANNEL = [
  {
    name: 'whatsapp',
    value: 'whatsapp',
  },
  { name: 'facebook', value: 'facebook' },
  { name: 'sms', value: 'sms' },
];

export const CHANNEL_OPTIONS = map(CHANNEL, (e) => ({
  value: e.value,
  label: e.name,
}));

// message directions bot-transactions
export const DIRECTIONS = {
  inbound: 'inbound',
  outbound: 'outbound',
};

export const BOT_STATUS = {
  active: true,
  inactive: false,
};

// agent status
export const AGENT_ACCOUNT_STATUSES = {
  online: 'online',
  offline: 'offline',
};

export const PURCHASE_STATUSES = {
  READ: 'READ',
  DELIVERED_TO_GATEWAY: 'NOT READ',
};

export const PURCHASE_STATUS_TOPUP = {
  allocated: 'Allocated',
  pending: 'Pending',
  rejected: 'Rejected',
};

export const SMS_STATUSES = ['SUCCESSFUL', 'PENDING', 'FAILED'];

export const PURCHASE_PAYMENT_STATUSES = {
  0: 'Not Paid',
  1: 'Paid',
  2: 'Partially Paid',
  3: 'Receipt Sent',
  4: 'Bonus',
};

export const PAYMENT_STATUSES = {
  0: 'Pending',
  1: 'Processed',
  2: 'Over Paid',
  3: 'Under Paid',
};

export const REPORT_DURATIONS = [
  { value: 'today', name: 'This Day' },
  { value: 'week', name: 'This Week' },
  { value: 'month', name: 'This Month' },
  { value: 'year', name: 'This Year' },
];

export const REPORT_DURATIONS_OPTIONS = map(REPORT_DURATIONS, (e) => ({
  value: e.value,
  label: e.name,
}));

export const MONTHS = [
  { name: 'January', value: 1 },
  { name: 'February', value: 2 },
  { name: 'March', value: 3 },
  { name: 'April', value: 4 },
  { name: 'May', value: 5 },
  { name: 'June', value: 6 },
  { name: 'July', value: 7 },
  { name: 'August', value: 8 },
  { name: 'September', value: 9 },
  { name: 'October', value: 10 },
  { name: 'November', value: 11 },
  { name: 'December', value: 12 },
];

export const MONTHS_OPTIONS = map(MONTHS, (month) => ({
  value: month.value,
  label: month.name,
}));

export const CONTACT_SCHEMA = {
  Phone: 'mob_no',
  AdditionalPhone: 'mob_no2',
  Title: 'title',
  FirstName: 'fname',
  LastName: 'lname',
  Gender: 'gender',
  DOB: 'birth_date',
  Address: 'address',
  Area: 'area',
  City: 'city',
  Country: 'country',
  Email: 'email',
};

export const PAYMENT_METHODS = [
  {
    type: 'Cash',
    summary:
      'Cash - Drop of payment at Mezzanine Floor, No 6, Zanaki St, Dar es Salaam',
  },
  {
    type: 'Cheque',
    summary: 'Cheque - Make cheque in the name of &apos;Beem&apos;',
  },
  {
    type: 'Bank Transfer',
    summary: 'Bank Transfer',
  },
  {
    type: 'Tigo Pesa',
    summary: 'Pay To Business Number',
    steps: [
      { step: 1, description: 'Dial *150*01#' },
      { step: 2, description: 'Choose Option to Make Payments' },
      { step: 3, description: 'Enter Business Number (222444)' },
      { step: 4, description: 'Enter Reference Number' },
      { step: 5, description: 'Enter Amount' },
      { step: 6, description: 'Enter Pin to Confirm' },
    ],
  },
  {
    type: 'M-Pesa',
    summary: 'Pay To Business Number',
    steps: [
      { step: 1, description: 'Dial *150*00#' },
      { step: 2, description: 'Choose Option to Make Payments' },
      { step: 3, description: 'Enter Business Number (222444) ' },
      { step: 4, description: 'Enter Reference Number' },
      { step: 5, description: 'Enter Amount' },
      { step: 6, description: 'Enter Pin to Confirm' },
    ],
  },
  {
    type: 'Airtel Money',
    summary: 'Pay To Business Number',
    steps: [
      { step: 1, description: 'Dial *150*06#' },
      { step: 2, description: 'Choose Option to Make Payments' },
      { step: 3, description: 'Enter Business Number (222444)' },
      { step: 4, description: 'Enter Reference Number' },
      { step: 5, description: 'Enter Amount' },
      { step: 6, description: 'Enter Pin to Confirm' },
    ],
  },
  {
    type: 'Halopesa',
    summary: 'Pay To Business Number',
    steps: [
      { step: 1, description: 'Dial *150*88#' },
      { step: 2, description: 'Choose Option to Make Payments' },
      { step: 3, description: 'Enter Business Number (222444)' },
      { step: 4, description: 'Enter Reference Number' },
      { step: 5, description: 'Enter Amount' },
      { step: 6, description: 'Enter Pin to Confirm' },
    ],
  },
];

export const DEFAULT_AIRTIME_CACHE = {
  scheduled: undefined,
  payload: [],
};

export const DEFAULT_CONTACTS_CACHE = {
  file: null,
  fileContent: [],
  groups: [],
  hasHeader: true,
};

export const PURCHASE_PAYMENT_STATUS_OPTIONS = map(
  PURCHASE_PAYMENT_STATUSES,
  (value, key) => ({
    value: key,
    label: value,
  })
);

export const PAYMENT_METHODS_OPTIONS = map(PAYMENT_METHODS, (method) => ({
  value: method.type,
  label: method.type,
}));

export const PURCHASE_STATUS_OPTIONS = map(PURCHASE_STATUSES, (value, key) => ({
  value: key,
  label: value,
}));

export const PURCHASE_STATUS_TOPUP_OPTIONS = map(
  PURCHASE_STATUS_TOPUP,
  (value, key) => ({
    value: key,
    label: value,
  })
);

export const USER_ROLE_OPTIONS = map(USER_ROLES, (value, key) => ({
  value: key,
  label: value,
}));

export const PAYMENT_STATUS_OPTIONS = map(PAYMENT_STATUSES, (value, key) => ({
  value: key,
  label: value,
}));

export const stepDescription = {
  whatsapp: [
    {
      step: 1,
      title: '1. Request For Channel',
      description:
        '- We have received your request for a new chat setup. Our team will contact you shortly to begin the onboarding process.',
    },
    {
      step: 2,
      title: '2. Verify Facebook Business Account',
      description: `
      - In order to proceed, you need to follow the steps highlighted in the guide <a href="https://drive.google.com/file/d/1s3EovoeSB9rWxx75k66cyACWN8cEOshZ/view">here</a> and contact us at <a href "mailto:support@beem.africa">support@beem.africa</a>.<br/>
      - Required documents<br/>
      <ol type="i">
      <li>Business registration ( or any legal document that mentions the business name)</li>
      <li>Tax Registration, utility bill or bank statement ( any legal document that has the business address including the phone number)</li>`,
    },
    {
      step: 3,
      title: '3. WhatsApp Business Account Verification',
      description:
        '- Your verification request has been submitted. You will receive an email notification once approved.',
    },
    {
      step: 4,
      title: '4. Facebook Security Verification',
      description: `We will need to link your Facebook & WhatsApp account and authorize API access for our platform.
      - In order to proceed, you need to follow the steps highlighted in the guide <a href="https://drive.google.com/file/d/1s3EovoeSB9rWxx75k66cyACWN8cEOshZ/view">here</a> and contact us at <a href "mailto:support@beem.africa">support@beem.africa</a>.`,
    },
    {
      step: 5,
      title: '5. WhatsApp Business Account Setup',
      description:
        '- We are working on setting up your WhatsApp Business Account. This may take a few days. You will receive an email notification once completed.',
    },
    {
      step: 6,
      title: '6. WhatsApp Number Verification',
      description:
        '- You will be receiving a call from our team and an OTP message on the WhatsApp Number for final verification.',
    },
    {
      step: 7,
      title: '7. Moja Number Setup',
      description: `- Congratulations! You're on the final stage, and we are connecting Moja with your WhatsApp number.`,
    },
    {
      step: 8,
      title: '8. Complete',
      description:
        '- Your channel has been successfully set up. For support, contact our support team by chatting with them <a href="https://drive.google.com/file/d/1s3EovoeSB9rWxx75k66cyACWN8cEOshZ/view">here</a> or send an email to <a href "mailto:support@beem.africa">support@beem.africa</a>.',
    },
  ],
  facebook: [
    {
      step: 1,
      title: '1. Request For Channel',
      description:
        '- We have received your request for a new chat setup. Our team will contact you shortly to begin the onboarding process.',
    },
    {
      step: 2,
      title: '2. Verify Facebook Business Account',
      description: `
      - In order to proceed, you need to follow the steps highlighted in the guide <a href="https://drive.google.com/file/d/1s3EovoeSB9rWxx75k66cyACWN8cEOshZ/view">here</a> and contact us at <a href "mailto:support@beem.africa">support@beem.africa</a>.<br/>
      - Required documents<br/>
      <ol type="i">
      <li>Business registration ( or any legal document that mentions the business name)</li>
      <li>Tax Registration, utility bill or bank statement ( any legal document that has the business address including the phone number)</li>`,
    },
    {
      step: 3,
      title: '3. Create Facebook App On Developer Account',
      description:
        '- You will need to create a Facebook App for Beem to be able to connect your account to your Facebook page. In order to proceed, you need to follow the steps highlighted in the guide <a href="https://drive.google.com/file/d/1s3EovoeSB9rWxx75k66cyACWN8cEOshZ/view">here</a> and contact us at <a href "mailto:support@beem.africa">support@beem.africa</a>.',
    },
    {
      step: 4,
      title: '4. Moja Facebook Account Setup',
      description:
        '- We will contact you to get the App ID & Secret Token from the App you created to connect the Facebook App with Moja.',
    },
    {
      step: 5,
      title: '5. Submit App For Review',
      description:
        '- Submit your Facebook App for review by following the guide and contact our support team to walk you through the process.',
    },
    {
      step: 6,
      title: '6. Complete',
      description:
        '- Your channel has been successfully set up. For support, contact our support team by chatting with them <a href="https://drive.google.com/file/d/1s3EovoeSB9rWxx75k66cyACWN8cEOshZ/view">here</a> or send an email to <a href "mailto:support@beem.africa">support@beem.africa</a>.',
    },
  ],
  instagram: [
    {
      step: 1,
      title: 'step 1',
      description: '<br/>verify instagram',
    },
    {
      step: 2,
      title: 'step 2',
      description: '<br/>verify instagram',
    },
    {
      step: 3,
      title: 'step 3',
      description: '<br/>verify instagram',
    },
    {
      step: 4,
      title: 'step 4',
      description: '<br/>verify instagram',
    },
  ],
  sms: [
    {
      step: 1,
      title: '1. Request For Channel',
      description:
        '- We have received your request for a new chat setup. Our team will contact you shortly to begin the onboarding process.',
    },
    {
      step: 2,
      title: '2. Validation',
      description:
        "- We would like to give you an update on your two-way SMS request validation. We have managed to submit your request and it is in the pending approval process with the Mobile Network Operators. The process may take 10-14 days. You'll receive an email update from us when it is complete.",
    },
    {
      step: 3,
      title: '3. Setup',
      description:
        '- We are in the final steps of your two-way SMS setup. We are activating your number in the countries you have selected.',
    },
    {
      step: 4,
      title: '4. Complete',
      description: `- You are now ready to send and recieve SMS messages on Moja. Kindly fill in the fields below with your API key and secret key which can be found <a href ='${
        process.env.REACT_APP_ENGAGE_URL
      }/?token=${Cookies.get(
        'token'
      )}' target="_blank">here</a> under <strong>API SETUP</strong>`,
    },
  ],
  google_business_messaging: [
    {
      step: 1,
      title: '1. Create Google Business Messages',
      description:
        '- To get started please create a <strong>Business Messages</strong> partner account then create & verify an agent.Follow the link<a href = https://business-communications.cloud.google.com/console/partner-console><strong> here.<strong></a>',
    },
    {
      step: 2,
      title: '2. Connect Google Business Messages',
      description:
        '- Submit your <strong>agent ID & brand ID</strong>. It is accessible on the agent page.',
    },
    {
      step: 3,
      title: '3. Authorization',
      description:
        '- Generate key file from the link <a href=https://business-communications.cloud.google.com/console/partner-console/partner/service-account> <strong>here</strong></a> and upload the file by clicking the button below.',
    },
    {
      step: 4,
      title: '4. Verify Connection',
      description:
        '- Copy the link and paste it in the webhook section of the integrations page, which is accessible on the agents page.&nbsp<strong><p>https://apichatcore.beem.africa/v1/chat</p></strong>',
    },
    {
      step: 5,
      title: '5. Setup Complete',
      description:
        '- Congratulations you have successfully completed the setup connect Moja to your google business messages',
    },
  ],
};

export const Weekdays = [
  {
    dayNo: 1,
    day: 'Monday',
    dayInitial: 'M',
  },
  {
    dayNo: 2,
    day: 'Tuesday',
    dayInitial: 'T',
  },
  {
    dayNo: 3,
    day: 'Wednesday',
    dayInitial: 'W',
  },
  {
    dayNo: 4,
    day: 'Thursday',
    dayInitial: 'T',
  },
  {
    dayNo: 5,
    day: 'Friday',
    dayInitial: 'F',
  },
  {
    dayNo: 6,
    day: 'Saturday',
    dayInitial: 'S',
  },
  {
    dayNo: 0,
    day: 'Sunday',
    dayInitial: 'S',
  },
];

export const DELETE_OPTIONS = [
  { value: 'selected', label: 'Delete Selected ' },
  { value: 'all', label: 'Delete All' },
];

export const DATE_FORMAT_DOB = 'YYYY-MM-DD';

export const TIME_LINE_FORMAT = 'MMM dd, yyyy';

export const ALLOCATE_OPTIONS = {
  add: 'Add Session',
  deduct: 'Deduct Session',
};

export const ALLOCATE_STATUS_OPTIONS = map(ALLOCATE_OPTIONS, (value, key) => ({
  value: key,
  label: value,
}));

export const UTILITY_TEMPLATE_CATEGORIES = [
  { value: 'alert_update', label: 'Alert Update', validWith: ['dotgo'] },
  { value: 'account_update', label: 'Account Update', validWith: ['dotgo'] },
  {
    value: 'appointment_update',
    label: 'Appointment Update',
    validWith: ['dotgo'],
  },
  { value: 'auto_reply', label: 'Auto Reply', validWith: ['dotgo'] },
  {
    value: 'issue_resolution',
    label: 'Issue Resolution',
    validWith: ['dotgo'],
  },
  { value: 'payment_update', label: 'Payment Update', validWith: ['dotgo'] },
  {
    value: 'personal_finance_update',
    label: 'Personal Finance Update',
    validWith: ['dotgo'],
  },
  {
    value: 'reservation_update',
    label: 'Reservation Update',
    validWith: ['dotgo'],
  },
  { value: 'shipping_update', label: 'Shipping Update', validWith: ['dotgo'] },
  {
    value: 'ticket_finance_update',
    label: 'Ticket Finance Update',
    validWith: ['dotgo'],
  },
  {
    value: 'transportation_update',
    label: 'Transportation Update',
    validWith: ['dotgo'],
  },
  { value: 'transactional', label: 'Transactional', validWith: ['dotgo_v2'] },
];

export const MARKETING_TEMPLATE_CATEGORIES = [
  { value: 'marketing', label: 'Marketing', validWith: ['dotgo_v2'] },
];

export const AUTHENTICATION_TEMPLATE_CATEGORIES = [
  {
    value: 'one_time_password',
    label: 'One Time Password',
    validWith: ['dotgo', 'dotgo_v2'],
  },
];

export const TEMPLATE_TYPES = [
  { value: undefined, label: 'None', mimeTypes: '' },
  { value: 'text', label: 'TEXT', mimeTypes: '' },
  {
    value: 'image',
    label: 'MEDIA: IMAGE',
    mimeTypes: 'image/jpeg, image/png, image/jpg',
  },
  {
    value: 'video',
    label: 'MEDIA: VIDEO',
    mimeTypes: 'video/mpeg, video/mp4',
  },
  {
    value: 'document',
    label: 'MEDIA: DOCUMENT',
    // mimeTypes:
    //   'application/pdf, application/msword, application/vnd.ms-powerpoint, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv',
    mimeTypes: 'application/pdf',
  },
];
export const TEMPLATE_VISIT_WEBSITE_ACTION = {
  value: 'visitWebsite',
  label: 'Visit Website',
};
export const TEMPLATE_CALL_PHONE_NUMBER_ACTION = {
  value: 'callPhoneNumber',
  label: 'Call Phone Number',
};
export const TEMPLATE_CALL_TO_ACTION_TYPES = [
  TEMPLATE_VISIT_WEBSITE_ACTION,
  TEMPLATE_CALL_PHONE_NUMBER_ACTION,
];
export const TEMPLATE_BUTTON_TYPES = [
  { value: null, label: 'None' },
  { value: 'callToAction', label: 'Call To Action' },
  { value: 'quickReply', label: 'Quick Reply' },
];

export const TEMPLATE_STATUS = [
  { value: 'requested', label: 'Requested' },
  { value: 'forwarded', label: 'Forwarded' },
  { value: 'enabled', label: 'Enabled' },
  { value: 'rejected', label: 'Rejected' },
];

export const VIEW_TYPE = [
  { value: 'basic', label: 'Messenger' },
  { value: 'kanban', label: 'Kanban' },
];

export const BUSINESS_VERTICAL_OPTIONS = [
  { value: 'Accounting & Auditing', label: 'Accounting & Auditing' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Airlines', label: 'Airlines' },
  { value: 'Amusement & Recreation', label: 'Amusement & Recreation' },
  { value: 'Arts & Culture', label: 'Arts & Culture' },
  { value: 'Banking', label: 'Banking' },
  { value: 'Business Services', label: 'Business Services' },
  { value: 'Chemical Industry', label: 'Chemical Industry' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consumer Discretionary', label: 'Consumer Discretionary' },
  { value: 'Consumer Services', label: 'Consumer Services' },
  { value: 'Consumer Staples', label: 'Consumer Staples' },
  { value: 'Ecommerce', label: 'Ecommerce' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Environment', label: 'Environment' },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Forestry', label: 'Forestry' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Heavy Industry', label: 'Heavy Industry' },
  { value: 'Hotels', label: 'Hotels' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Infrastructure', label: 'Infrastructure' },
  { value: 'Insurance Law', label: 'Insurance Law' },
  { value: 'Life Sciences', label: 'Life Sciences' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Manufacturing Media', label: 'Manufacturing Media' },
  { value: 'Mining', label: 'Mining' },
  { value: 'Non-profits', label: 'Non-profits' },
  { value: 'Professional Services', label: 'Professional Services' },
  { value: 'Publishing', label: 'Publishing' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Restaurants', label: 'Restaurants' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Science & Technology', label: 'Science & Technology' },
  { value: 'Space', label: 'Space' },
  { value: 'Telecom', label: 'Telecom' },
  { value: 'Tourism', label: 'Tourism' },
  { value: 'Transportation', label: 'Transportation' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Wholesale', label: 'Wholesale' },
  { value: 'Other', label: 'Other' },
];

export const TWO_WAY_NUMBER_TYPE = [
  { value: 'ShortCode', label: 'Short Code' },
  { value: 'LongCode', label: 'Long Code' },
];
export const TWO_WAY_CODE_TYPE = [
  { value: 'Shared', label: 'Shared' },
  { value: 'Dedicated', label: 'Dedicated' },
];
export const TWO_WAY_BILLING_TYPE = [
  {
    value: 'Toll Free/ Free to End User',
    label: 'Toll Free/ Free to End User',
  },
  { value: 'Premium', label: 'Premium' },
  { value: 'Standard Rate', label: 'Standard Rate' },
];

export const TWO_WAY_USAGE = [
  {
    value: 'I will integrate with my Application',
    label: 'I will integrate with my Application',
  },
  { value: 'I will use BEEM platform', label: 'I will use BEEM platform' },
];

export const BUNDLE_SUBSCRIPTION_DURATION = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' },
];
