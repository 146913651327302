/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import forEach from 'lodash/forEach';
import lodashGet from 'lodash/get';
import { singularize, pluralize } from 'inflection';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

/* declarations */
let client;
let BASE_URL;
let userId;
let jwtToken;
let expiryTime;
let role;
let authUrl;
let apps;
let locale;
let themeColor;
let logoUrl;
let supportPhone;
let phone;
let organization;
let paymentMethod;
let tokenPayload;
let current_app;
let lastLogin;
let accessToken;
let username;
let parentId;
let email;
let defaultCountry;
let defaultCurrency;

/**
 * @function mapResponseToError
 * @name mapResponseToError
 * @description convert axios error to js native error
 * @param {object} exception axios http error response
 * @returns {Promise} promise rejection
 * @see {@link https://github.com/axios/axios#handling-errors}
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const mapResponseToError = (exception) => {
  // obtain error details
  let { code, status, message, description, stack, errors, data } = exception;
  const { request, response } = exception;

  // handle server response error
  if (response) {
    code = response.code || code;
    status = response.status || status;
    data = response.data || data || {};
    message = data.message || response.statusText || message;
    errors = response.errors || errors || {};
    stack = response.stack || data.stack || stack;
  }

  // handle no server response
  if (request) {
    description = description || 'Server Not Responding';
  }

  // initialize error
  let error = new Error(message);
  error.stack = stack;

  // update error object
  error = merge(error, { code, status, message, description, errors, ...data });

  // return normalized native error
  return Promise.reject(error);
};

/**
 * @function mapResponseToData
 * @name mapResponseToData
 * @description convert axios http response to data
 * @param {object} response axios http response
 * @returns {object} response data
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const mapResponseToData = (response) => response.data;

/**
 * @function wrapRequest
 * @name wrapRequest
 * @description wrap http request and convert response to error or data
 * @param {Promise} request valid axios http request object
 * @returns {Promise} request with normalized response error and data
 * @since 0.1.0
 * @version 0.1.0
 * @private
 */
const wrapRequest = (request) => {
  return request.then(mapResponseToData).catch(mapResponseToError);
};

/**
 * @name CONTENT_TYPE
 * @description supported content type
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 */
export const CONTENT_TYPE = 'application/json';

/**
 * @function createHttpClient
 * @name createHttpClient
 * @description create an http client if not exists
 * @param {string} API_BASE_URL base url to use to api calls
 * @returns {object} A new instance of Axios
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 * import { createHttpClient } from './client';
 * const httpClient = createHttpClient();
 */
export const createHttpClient = (API_BASE_URL) => {
  if (!client) {
    BASE_URL =
      API_BASE_URL ||
      process.env.BEEM_API_URL ||
      process.env.REACT_APP_BEEM_API_URL;
    // eslint-disable-next-line no-use-before-define
    const options = { baseURL: BASE_URL, headers: HEADERS };
    client = axios.create(options);
    client.id = Date.now();
  }

  return client;
};

/**
 * @function disposeHttpClient
 * @name disposeHttpClient
 * @description reset current http client in use.
 * @returns {object} null
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { disposeHttpClient } from './client';
 * disposeHttpClient();
 */
export const disposeHttpClient = () => {
  client = null;
  return client;
};

/**
 * @function all
 * @name all
 * @description performing multiple concurrent requests.
 * @param {Function[]} promises Array of function to be run in parallel
 * @returns {Promise} Promise Instance
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { all, spread } from './client';
 * const request = all(getProfile(), getPlans());
 * request.then(spread((incidentTypes, plans) => { ... }));
 */
export const all = (...promises) => axios.all([...promises]);

/**
 * @function spread
 * @name spread
 * @description Flattened array fulfillment to the formal parameters of the
 * fulfillment handler.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { all, spread } from './client';
 * const request = all(getProfile(), getPlans());
 * request.then(spread((incidentTypes, plans) => { ... }));
 */
export const spread = axios.spread; // eslint-disable-line

/**
 * @function prepareParams
 * @name prepareParams
 * @description convert api query params as per API filtering specifications
 * @param {object} params api call query params
 * @returns {object} http params to be sent to server
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 */
export const prepareParams = (params) => {
  const normalizedParams = {};
  const { sort, page, pageSize, ...otherParams } = params || {};

  if (sort) {
    forEach(params.sort, (value, key) => {
      normalizedParams.sortBy = key;
      normalizedParams.sortOrder = value;
    });
  }

  if (page) {
    normalizedParams.page = params.page;
  }

  if (pageSize) {
    normalizedParams.pageSize = params.pageSize;
  }

  if (otherParams) {
    forEach(otherParams, (value, key) => {
      normalizedParams[key] = value;
    });
  }

  return normalizedParams;
};

/**
 * @function get
 * @name get
 * @description issue http get request to specified url.
 * @param {string} url valid http path.
 * @param {object} [params] params that will be encoded into url query params.
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { get } from './client';
 *
 */
export const get = (url, params) => {
  const httpClient = createHttpClient();
  const options = prepareParams(params);
  return wrapRequest(httpClient.get(url, { params: options }));
};

/**
 * @function post
 * @name post
 * @description issue http post request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { post } from './client';
 *
 * const postUser = post('/users', { age: 14 });
 * postUser.then(user => { ... }).catch(error => { ... });
 */
export const post = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.post(url, data));
};

/**
 * @function put
 * @name put
 * @description issue http put request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { put } from './client';
 *
 * const putUser = put('/users/5c1766243c9d520004e2b542', { age: 11 });
 * putUser.then(user => { ... }).catch(error => { ... });
 */
export const put = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.put(url, data));
};

/**
 * @function patch
 * @name patch
 * @description issue http patch request to specified url.
 * @param {string} url valid http path.
 * @param {object} data request payload to be encoded on http request body
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { patch } from './client';
 *
 * const patchUser = patch('/users/5c1766243c9d520004e2b542', { age: 10 });
 * patchUser.then(user => { ... }).catch(error => { ... });
 */
export const patch = (url, data) => {
  if (isEmpty(data)) {
    return Promise.reject(new Error('Missing Payload'));
  }
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.patch(url, data));
};

/**
 * @function del
 * @name del
 * @description issue http delete request to specified url.
 * @param {string} url valid http path.
 * @returns {Promise} promise resolve with data on success or error on failure.
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { del } from './client';
 *
 * const deleteUser = del('/users/5c1766243c9d520004e2b542');
 * deleteUser.then(user => { ... }).catch(error => { ... });
 */
export const del = (url) => {
  const httpClient = createHttpClient();
  return wrapRequest(httpClient.delete(url));
};

/**
 * @function
 * @name normalizeResource
 * @description Normalize resource by adding singular and plural
 * names to it
 * @param {string} resource valid api resource name
 * @returns {object} Object contains singular and plural names for resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const normalizeResource = (resource) => {
  const singular = singularize(resource.name);
  const plural = pluralize(resource.name);

  return { singular, plural };
};

/**
 * @function
 * @name variableNameFor
 * @description Generate camel cased variable name based on provided strings
 * @param  {...string} names array of names to be used in variable name
 * @returns {string} camel cased name
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const variableNameFor = (...names) => camelCase([...names]);

/**
 * @function
 * @name createGetListHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createGetListHttpAction = (resource) => {
  const { plural } = normalizeResource(resource);

  const methodName = variableNameFor('get', plural);

  return {
    [methodName]: (params) => {
      const endpoint = `/${plural}`;

      return get(endpoint, params);
    },
  };
};

/**
 * @function
 * @name createGetSingleHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createGetSingleHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('get', singular);

  return {
    [methodName]: (id) => {
      const endpoint = `/${plural}/${id}`;

      return get(endpoint);
    },
  };
};

/**
 * @function
 * @name createPostHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPostHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('post', singular);

  return {
    [methodName]: (payload) => {
      const { authHeader: name } = payload;
      // eslint-disable-next-line no-param-reassign
      payload.name = name;
      const endpoint = `/${plural}`;

      return post(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createPutHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPutHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('put', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}`;

      return put(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createPatchHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createPatchHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('patch', singular);

  return {
    [methodName]: (payload) => {
      const endpoint = `/${plural}/${payload.id}`;

      return patch(endpoint, payload);
    },
  };
};

/**
 * @function
 * @name createDeleteHttpAction
 * @param {string} resource Api resource name
 * @returns {object} http actions to interact with a resource
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const createDeleteHttpAction = (resource) => {
  const { singular, plural } = normalizeResource(resource);

  const methodName = variableNameFor('delete', singular);

  return {
    [methodName]: (id) => {
      const endpoint = `/${plural}/${id}`;

      return del(endpoint);
    },
  };
};

/**
 * @function createHttpActionsFor
 * @name createHttpActionsFor
 * @description generate http actions to interact with resource
 * @param {string} resource valid http resource
 * @returns {object} http actions to interact with a resource
 * @since 0.1.0
 * @version 0.1.0
 * @example
 * import { createHttpActionsFor } from './client';
 *
 * const { deleteUser } = createHttpActionsFor('user');
 * deleteUser('5c176624').then(user => { ... }).catch(error => { ... });
 */
export const createHttpActionsFor = (resource) => {
  const getResources = createGetListHttpAction(resource);
  const getResource = createGetSingleHttpAction(resource);
  const postResource = createPostHttpAction(resource);
  const putResource = createPutHttpAction(resource);
  const patchResource = createPatchHttpAction(resource);
  const deleteResource = createDeleteHttpAction(resource);

  return {
    ...getResources,
    ...getResource,
    ...postResource,
    ...putResource,
    ...patchResource,
    ...deleteResource,
  };
};

/**
 * @function singin
 * @name singin
 * @description Signin user with provided credentials
 * @param {object} credentials Username and password
 * @returns {object} Object having party, permission and other meta data
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 * @example
 * import { signin } from './client';
 *
 * signin({ email:'', password:'' }).then(results => {});
 */
export const signin = (credentials) => {
  const defaultCredentials = { email: '', password: '' };
  const payload = isEmpty(credentials)
    ? defaultCredentials
    : merge(defaultCredentials, credentials);
  return post('/auth/login', payload).then((results) => {
    // persist token and party in session storage
    localStorage.setItem('token', results.data.access_token); // eslint-disable-line
    localStorage.setItem('userId', results.data.id); // eslint-disable-line
    userId = results.data.id;
    jwtToken = results.data.access_token;

    return results;
  });
};

/**
 * @function
 * @name signout
 * @description Signout function and cleanup localStorage
 * @param {object} token Base64 Encoded jwt token
 * @returns {undefined}
 * @version 0.1.0
 * @since 0.1.0
 */
export const signout = (token) => {
  return axios
    .delete(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/logout`, {
      data: {
        short_token: token,
      },
    })
    .then(() => {
      Cookies.remove('payload');
      Cookies.remove('token');
      window.location.replace(process.env.REACT_APP_AUTH_REDIRECT_URL); // eslint-disable-line
    })
    .catch(() => {
      Cookies.remove('payload');
      Cookies.remove('token');
      window.location.replace(process.env.REACT_APP_AUTH_REDIRECT_URL); // eslint-disable-line
    });
};

/**
 * @function
 * @name getJwtToken
 * @description retrieve jwt token from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getJwtToken = () => {
  if (isEmpty(jwtToken)) {
    jwtToken = Cookies.get('token');
  }
  return jwtToken;
};

/**
 * @function
 * @name getAccessToken
 * @description retrieve access token from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getAccessToken = () => {
  if (isEmpty(accessToken)) {
    const payload = Cookies.getJSON('payload');
    accessToken = lodashGet(payload, 'token', '');
  }
  return accessToken;
};

/**
 * @function
 * @name getTokenPayload
 * @description Return decoded token payload
 * @param {string} token jwt token base64 url encoded token
 * @returns {object} Jwt token content
 * @version 0.1.0
 * @since 0.1.0
 */
export const getTokenPayload = (token) => {
  if (isEmpty(tokenPayload)) {
    try {
      tokenPayload = jwtDecode(token);
    } catch (error) {
      console.log(error);
    }
  }
  return tokenPayload;
};

/**
 * @function
 * @name getLastLogin
 * @description retrieve last login from local storage if not set
 * @returns {string| undefined} jwt token
 * @since 0.1.0
 * @version 0.1.1
 */
export const getLastLogin = () => {
  if (isEmpty(lastLogin)) {
    const payload = Cookies.getJSON('payload');
    lastLogin = lodashGet(payload, 'last_login', '');
  }
  return lastLogin;
};
/**
 * @function
 * @name getExpiryTime
 * @description Retrieve token expiry timestamp
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const getExpiryTime = () => {
  if (isEmpty(expiryTime)) {
    const payload = Cookies.getJSON('payload');
    expiryTime = lodashGet(payload, 'exp', '');
  }
  return expiryTime;
};

/**
 * @function
 * @name getUserId
 * @description retrieve userId from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getUserId = () => {
  if (isEmpty(userId)) {
    const payload = Cookies.getJSON('payload');
    userId = lodashGet(payload, 'user_id', '');
  }
  return userId;
};

/**
 * @function
 * @name getRole
 * @description retrieve role from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getRole = () => {
  if (isEmpty(role)) {
    const payload = Cookies.getJSON('payload');
    role = lodashGet(payload, 'role', 'vendor');
  }
  return role;
};

/**
 * @function
 * @name getParentId
 * @description retrieve role from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getParentId = () => {
  if (isEmpty(parentId)) {
    const payload = Cookies.getJSON('payload');
    parentId = lodashGet(payload, 'parent_id', '');
  }
  return parentId;
};

/**
 * @function
 * @name getLocale
 * @description retrieve locale from local storage if not set
 *
 * @returns {string |undefined} locale i.e en, sw
 * @since 0.1.0
 * @version 0.1.0
 */
export const getLocale = () => {
  if (isEmpty(locale)) {
    const payload = Cookies.getJSON('payload');
    locale = lodashGet(payload, 'locale', 'en');
  }

  return locale;
};

/**
 * @function
 * @name getApps
 * @description retrieve apps Url from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getApps = () => {
  if (isEmpty(apps)) {
    const payload = Cookies.getJSON('payload');
    apps = JSON.stringify(lodashGet(payload, 'apps', ''));
  }

  return apps || [];
};

/**
 * @function
 * @name getAuthUrl
 * @description retrieve authentication app Url from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getAuthUrl = () => {
  if (isEmpty(authUrl)) {
    const payload = Cookies.getJSON('payload');
    authUrl = JSON.stringify(lodashGet(payload, 'auth_url', ''));
  }

  return authUrl;
};

/**
 * @function
 * @name getThemeColor
 * @description retrieve application theme color from local storage if not set
 *
 * @returns {string |undefined} theme color
 * @since 0.1.0
 * @version 0.1.0
 */
export const getThemeColor = () => {
  if (isEmpty(themeColor)) {
    const payload = Cookies.getJSON('payload');
    themeColor = lodashGet(payload, 'theme_color', '#33b1ba');
  }

  return themeColor;
};

/**
 * @function
 * @name getLogoUrl
 * @description retrieve application logo from local storage if not set
 *
 * @returns {string |undefined} logo url
 * @since 0.1.0
 * @version 0.1.0
 */
export const getLogoUrl = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(logoUrl)) {
    if (!isEmpty(payload)) {
      logoUrl = lodashGet(payload.parent_profile, 'logo_url', '');
    }
  }

  return logoUrl;
};

/**
 * @function
 * @name getSupportPhone
 * @description retrieve support phone from local storage if not set
 *
 * @returns {string |undefined} support phone number
 * @since 0.1.0
 * @version 0.1.0
 */
export const getSupportPhone = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(supportPhone)) {
    if (!isEmpty(payload)) {
      supportPhone = lodashGet(payload.parent_profile, 'support_phone', '');
    }
  }

  return supportPhone;
};

/**
 * @function
 * @name getPhone
 * @description retrieve phone from local storage if not set
 *
 * @returns {string |undefined} phone
 * @since 0.1.0
 * @version 0.1.0
 */
export const getPhone = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(phone)) {
    if (!isEmpty(payload)) {
      phone = lodashGet(payload.parent_profile, 'phone', '');
    }
  }

  return phone;
};

/**
 * @function
 * @name getOrganization
 * @description retrieve organization name color from local storage if not set
 *
 * @returns {string |undefined} organization name
 * @since 0.1.0
 * @version 0.1.0
 */
export const getOrganization = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(organization)) {
    if (!isEmpty(payload)) {
      organization = lodashGet(payload.parent_profile, 'organisation', '');
    }
  }

  return organization;
};

/**
 * @function
 * @name getPaymentMethod
 * @description Retrieve payment method set by reseller for sub-accounts
 *
 * @returns {string |undefined} payment method for sub-account
 * @since 0.1.0
 * @version 0.1.0
 */
export const getPaymentMethod = () => {
  const payload = Cookies.getJSON('payload');
  if (isEmpty(paymentMethod)) {
    if (!isEmpty(payload)) {
      paymentMethod = lodashGet(payload.parent_profile, 'payment_method', '');
    }
  }

  return paymentMethod;
};

/**
 * @function
 * @name getCurrentApp
 * @description retrieve current application details from local storage if not set
 *
 * @returns {string |undefined} user id
 * @since 0.1.0
 * @version 0.1.0
 */
export const getCurrentApp = () => {
  if (isEmpty(current_app)) {
    const payload = Cookies.getJSON('payload');
    current_app = JSON.stringify(lodashGet(payload, 'current_app', ''));
  }

  return current_app;
};

/**
 * @function
 * @name getUsername
 * @description retrieve username
 *
 * @returns {string |undefined} username
 * @since 0.1.0
 * @version 0.1.0
 */
export const getUsername = () => {
  if (isEmpty(username)) {
    const payload = Cookies.getJSON('payload');
    username = lodashGet(payload, 'username', '');
  }
  return username;
};

export const getEmail = () => {
  if (isEmpty(email)) {
    const payload = Cookies.getJSON('payload');
    email = lodashGet(payload, 'email', '');
  }
  return email;
};

/**
 * @function
 * @name getDefaultCurrency
 * @description Retrieve token expiry timestamp
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDefaultCurrency = () => {
  if (isEmpty(defaultCurrency)) {
    const payload = Cookies.getJSON('payload');
    defaultCurrency = lodashGet(payload, 'currency', '');
  }
  return defaultCurrency;
};
/**
 * @function
 * @name getDefaultCountry
 * @description Retrieve token expiry timestamp
 *
 * @returns {number} expiry timestamp
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDefaultCountry = () => {
  if (isEmpty(defaultCountry)) {
    const payload = Cookies.getJSON('payload');
    defaultCountry = lodashGet(payload, 'country', '');
  }
  return defaultCountry;
};

export const saveToken = (token, client_id) => {
  const defaultCredentials = Cookies.get('token');
  const payload = isEmpty(token) ? defaultCredentials : token;
  jwtToken = payload;

  const body = { short_token: payload };
  if (client_id) {
    body.client_id = client_id;
  }
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/getUserData`,
    {
      ...body,
    }
  ).then((response) => {
    const debug = process.env.NODE_ENV !== 'test';
    Cookies.set('payload', response.data, { secure: debug });
    Cookies.set('token', response.data.token, { secure: debug });

    userId = response.data.user_id;
    role = response.data.role;
    locale = response.data.locale;
    apps = response.data.apps;
    expiryTime = response.data.exp;
    email = response.data.email;
    authUrl = response.data.auth_url;
    accessToken = response.data.access_token;
    themeColor = '#33b1ba';
    parentId = response.data.parent_id;
    defaultCountry = response.data.country;
    defaultCurrency = response.data.currency;

    if (!isEmpty(response.data.parent_profile)) {
      themeColor = response.data.parent_profile.theme_color;
      logoUrl = response.data.parent_profile.logo_url;
      supportPhone = response.data.parent_profile.support_phone;
      phone = response.data.parent_profile.phone;
      organization = response.data.parent_profile.organisation;
      paymentMethod = response.data.parent_profile.payment_method;
    }
    window.location.replace(`/#!/dashboard?token=${token}`); // eslint-disable-line
    return response.data;
  });
};

/**
 * @function
 * @name isValidUser
 * @description Valid if the current user is valid
 * @returns {boolean} True if valid and false otherwise
 * @version 0.1.0
 * @since 0.1.0
 */
export const isValidUser = () => {
  jwtToken = getJwtToken();

  userId = getUserId();

  expiryTime = getExpiryTime();

  if (isEmpty(jwtToken) || isEmpty(userId)) {
    return false;
  }

  if (expiryTime && expiryTime > Math.round(Date.now() / 1000)) {
    return true;
  }

  return false;
};

/**
 * @name HEADERS
 * @description default http headers
 * @since 0.1.0
 * @version 0.1.0
 * @static
 * @public
 */
export const HEADERS = {
  Accept: CONTENT_TYPE,
  'Content-Type': CONTENT_TYPE,
  Authorization: getJwtToken(),
};

/**
 * @function
 * @name getUsers
 * @description Get
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const getUsers = (params) => {
  return get('/admins/users', params);
};

/**
 * -----------------------------------------------------------------------------
 * ChatBot Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name putadminBot
 * @description put
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const putBots = (data) => {
  return put('/admins/bot', data);
};

export const postBots = (data) => {
  return post('/bot', data);
};

export const putBotclient = (data) => {
  return put('/bots', data);
};

// get chat channel settings
export const getChatBots = (data) => {
  return get('/bots', data);
};

// put chat channel settings
export const putChatBot = (id) => {
  return put(`/bots/${id}`);
};

export const getOneChatBot = (id) => {
  return get(`/bots/${id}`);
};
// post chat channel settings
export const postChatBot = (data) => {
  return post('/bots', data);
};

export const facebookSelfsignup = (data) => {
  return post('/self-signup/facebook', data);
};
export const instagramSelfsignup = (data) => {
  return post('/self-signup/instagram', data);
};

export const putChatMsg = (data) => {
  return put('/bot', data);
};

export const getBotTransactions = (data) => {
  return get('/transactions', data);
};

export const getBotSessions = (data) => {
  return get('/sessions', data);
};

export const postChatOnboard = (data) => {
  return post('/chat-onboarding', data);
};

export const getChatOnboard = (id, data) => {
  return get(`/chat-onboarding/${id}`, data);
};

export const putChatOnboard = (id, data) => {
  return put(`/chat-onboarding/${id}`, data);
};

export const getWorkingHrs = (params) => {
  return get(`/working-hours/`, params);
};

export const postWorkingHrs = (data) => {
  return post('/working-hours', data);
};

export const getChannelAggregate = (data) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/reports/chatbot-reports/channel-counts`,
    data
  );
};

export const getAverageResponseTime = (data) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/reports/chatbot-reports/average-response`,
    data
  );
};

export const getAgentAggregate = (data) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/reports/chatbot-reports/agent-counts`,
    data
  );
};

export const getCustomerAggregate = (data) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/reports/chatbot-reports/customer-counts`,
    data
  );
};

export const getAgentTransferAggregate = (data) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/reports/chatbot-reports/agent-transfer-counts`,
    data
  );
};

/**
 * @function
 * @name postAgent
 * @description post
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

// post Agent
export const postAgent = (data) => {
  return post(`/agents`, data);
};

/**
 * @function
 * @name putAgent
 * @description put
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

// put Agent
export const putAgent = (data) => {
  return put(`/agents/${data.id}`, data);
};

export const putUserStatus = (data) => {
  return put(`/users`, data);
};

export const getAgent = (data) => {
  return get(`/agents/${data.id}`, data);
};

export const getProfile = () => {
  return get(`/agents/${userId}`);
};

/**
 * @function
 * @name putUsers
 * @description Get
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const putUsers = (data) => {
  return put(`/users/${data.id}`, data);
};

export const deactivateUser = (data) => {
  return put(`/users/deactivate/${data.id}`, data);
};

export const putVendors = (data) => {
  return put(`${process.env.REACT_APP_SMS_API_URL}/vendors/${data.id}`, data);
};

/**
 * @function
 * @name getUserById
 * @description Get
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const getUserById = () => {
  return get(`${process.env.REACT_APP_SMS_API_URL}/vendors/${getUserId()}`);
};

/**
 * @function
 * @name getRoutes
 * @description Get Routes in admin
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRoutes = (params) => {
  return get('/client-routes', params);
};

/**
 * @function
 * @name getAirtimeRoutes
 * @description Get Routes in admin
 * @param {object} params Params for filtering
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAirtimeRoutes = (params) => {
  return get('/airtime-routes', params);
};

/**
 * @function
 * @name postRoutes
 * @description Create a new route in admin
 * @param {object} data  Route object
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postRoutes = (data) => {
  return post('/client-routes', data);
};

/**
 * @function
 * @name putClientRoutes
 * @description Create a new route in admin
 * @param {object} data  Route object
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const putClientRoutes = (data) => {
  return put(`/client-routes/${data.id}`, data);
};

/**
 * -----------------------------------------------------------------------------
 * API Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name postAPI
 * @description Add API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postAPI = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/generate-apiKey`,
    data
  );
};

/**
 * @function
 * @name putAPI
 * @description Edit API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const putAPI = (data) => {
  return put(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/disable-apikey`,
    data
  );
};

export const getAPI = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/apikey`,
    params
  );
};
/**
 * @function
 * @name postSecret
 * @description Add API
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const postSecret = (data) => {
  return post(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/auth/generate-secretkey`,
    data
  );
};
/**
 * -----------------------------------------------------------------------------
 * Reports Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 *@name getALLTRANSACTIONSReport
 * @description Get Broadcast Statistic Report
 * @param {object} params Params object
 * @returns {Promise} Axios Api call promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const getALLTRANSACTIONSReport = (params) => {
  return get(`/reports/transactions`, params);
};

export const getAdmintransaction = (params) => {
  return get(`/reports/transactions`, params);
};
/**
 * @function
 * @name generateExport
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const generateExport = (data) => {
  return post('/reports/exports', data);
};

/**
 * @function
 * @name getAccountBalance
 * @param {object} params GET params
 *
 *
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAccountBalance = (params) => {
  return get('/vendors/balance', params);
};

/**
 * @function
 * @name getCountries
 * @description Get countries which are static values
 * @param {object} params additional param for search countries
 *
 * @returns {Promise} Promise which resolves to data
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCountries = (params) => {
  return get(
    `${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/static/countries`,
    params
  );
};

/**
 * @function
 * @name getNetworks
 * @description Get list of networks
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getNetworks = (params) => {
  return get('/networks', params);
};

/**
 * @function
 * @name getBalance
 * @description Get list of networks
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */

export const getBalance = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance`,
    params
  );
};

export const token = (params) => {
  return get('/agents/manager-token', params);
};

export const getManagerBalance = async () => {
  const managerToken = await token({ user_id: getParentId(), role: 'admin' });

  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance?app_name=${process.env.REACT_APP_APP_NAME}`,
    headers: {
      Authorization: `Bearer ${managerToken.data.token}`,
      'Content-Type': 'application/json',
    },
  };

  return axios(config);
};

/**
 * @function
 * @name updateTopupBalance
 * @description Get list of networks
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */

export const updateTopupBalance = (params) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance`,
    params
  );
};

/**
 * @function
 * @name getProducts
 * @description Get list of products
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getProducts = (params) => {
  return get('/products', params);
};

/**
 * -----------------------------------------------------------------------------
 * Send Bulk Airtime Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name previewAirtime
 * @description Send Airtime template for composition
 * @param {*} data Data to be send for validation
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const previewAirtime = (data) => {
  return post('/trans/preview', data);
};

/**
 * @function
 * @name sendAirtime
 * @description Send Airtime to validated numbers
 * @param {object} data  Data object contains redis reference key for sending Airtime
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendAirtime = (data) => {
  return post('/trans/send', data);
};

/**
 * @function
 * @name validateAirtimeTransfer
 * @description Validate Airtime contacts
 * @param {*} data Data to be send for validation
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const validateAirtimeTransfer = (data) => {
  return post('/trans/validate', data);
};

/**
 * -----------------------------------------------------------------------------
 * Topup Airtime Balance Endpoints
 * -----------------------------------------------------------------------------
 */

/**
 * @function
 * @name completePurchase
 * @description Mark completion of Message purchase flow
 * @param {object} data  Data object contains redis reference key
 *  for purchasing sms
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const completePurchase = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests/complete`,
    data
  );
};

/**
 * @function
 * @name getPaymentMethods
 * @description Get list of available payment methods
 * @param {object} params GET params
 * @returns {Promise} GET promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const getPaymentMethods = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods`,
    params
  );
};

export const postPaymentMethods = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods`,
    data
  );
};

export const putPaymentMethods = (data) => {
  return put(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods/${data.id}`,
    data
  );
};

export const deletePaymentMethods = (data) => {
  return del(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/payment-methods/${data.id}`
  );
};

/**
 * @function
 * @name postPurchase
 * @description Mark completion of Message purchase flow
 * @param {object} data  Data object contains redis reference key
 *  for purchasing sms
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const postPurchase = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests`,
    data
  );
};

export const getPurchases = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests`,
    params
  );
};

export const postPushPurchase = (data) => {
  return post(`${process.env.REACT_APP_TOPUP_REDIRECT_URL}/request-pay`, data);
};

/**
 * @function
 * @name putPurchases
 * @description Mark completion of Message purchase flow
 * @param {object} data  Data object contains redis reference key
 *  for purchasing sms
 *
 * @returns {Promise} Post Request
 * @version 0.1.0
 * @since 0.1.0
 */
export const putPurchases = (data) => {
  return put(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-requests/${data.id}`,
    data
  );
};

export const getPayments = (params) => {
  return get(`${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments`, params);
};

export const getCreditHistory = (params) => {
  return get(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-histories`,
    params
  );
};

/**
 * @function
 * @name addCredits
 * @description Add credits to sub-accounts or vendors
 *
 * @param {object} data  Add credit data
 * @returns {Promise} Post promise response
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const addCredits = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/payments/assign-credits`,
    data
  );
};

/**
 * @function
 * @name deductCredits
 * @description Deduct credits from vendors
 *
 * @param {object} data  Deduct credit data
 * @returns {Promise} Post promise response
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const deductCredits = (data) => {
  return post(
    `${process.env.REACT_APP_TOPUP_REDIRECT_URL}/credit-balance/deduct-account-balance`,
    data
  );
};

export const getChatUsers = (data) => {
  return get('/users', data);
};

export const getContactUsers = (params) => {
  return get(`/chat-users`, params);
};

export const getResolvedCases = (params) => {
  return get(`/resolved-cases`, params);
};

export const getKanbanUsers = (params) => {
  return get(`/chat-users-kanban`, params);
};

export const updateContactUsers = (id, data) => {
  return put(`${process.env.REACT_APP_CONTACT_URL}/contacts/${id}`, data);
};
export const getContactDetails = (id, data) => {
  return get(`${process.env.REACT_APP_CONTACT_URL}/contacts/${id}`, data);
};
export const getChatbotContactDetails = (mobno) => {
  return get(
    `${process.env.REACT_APP_CONTACT_URL}/contacts/getChatbot/${mobno}`
  );
};

export const getInactiveContacts = (params) => {
  return get(`/inactive-sessions`, params);
};
/**
 * @function
 * @name getRoles
 * @description Get API
 * @param {object} params Additional params for filtering
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRoles = (params) => {
  return get(`${process.env.REACT_APP_AUTH_REDIRECT_URL}/api/roles`, params);
};

export const resolveSession = (data) => {
  return post('/resolve-session', data);
};

export const transferSession = (data) => {
  return post('/transfer-agent', data);
};

export const getTotalSessions = (params) => {
  return get(`/reports/session-counts`, params);
};
export const getTotalTransferToSupport = (params) => {
  return get(`/reports/transfer-counts`, params);
};
export const getSessionGraphData = (params) => {
  return get(`/reports/session-graph`, params);
};
export const getTransactionGraphData = (params) => {
  return get(`/reports/chat-activity`, params);
};
export const fetchMessages = (params) => {
  return get(`/chat-transactions`, params);
};
export const sendChatMessage = (params) => {
  return post(`/chat-send`, params);
};
export const postNotes = (data) => {
  return post('/notes', data);
};
export const getNotes = (params) => {
  return get('/notes', params);
};

export const deleteNotes = (id) => {
  return del(`/notes/${id}`);
};
export const postLabels = (data) => {
  return post('/labels', data);
};
export const getLabels = (params) => {
  return get('/labels', params);
};
export const getAddedLabels = (params) => {
  return get('/labels/assignments', params);
};

export const postAssignLabels = (data) => {
  return post('/labels/assignments', data);
};

// Tickets
export const postTickets = (data) => {
  return post('/tickets', data);
};

// Quick Replies
export const getQuickReplies = (params) => {
  return get('/quick-replies', params);
};

export const postQuickReplies = (data) => {
  return post('/quick-replies', data);
};

export const deleteQuickReplies = (id, data) => {
  return put(`/quick-replies/${id}`, data);
};

// Image Quick Replies
export const sendImageFile = (data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BEEM_API_URL}quick-replies`,
    headers: {
      Authorization: getAccessToken(),
      'Content-Type': 'multipart/form-data',
    },
    data,
  };

  return axios(config);
};
export const getCatalogImages = (params) => {
  return get('/attachments', params);
};

export const sendFileData = (data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BEEM_API_URL}chat-send`,
    headers: {
      Authorization: getAccessToken(),
      'Content-Type': 'multipart/form-data',
    },
    data,
  };

  return axios(config);
};
export const uploadGoogleBusinessKey = (data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BEEM_API_URL}google-business-config`,
    headers: {
      Authorization: getAccessToken(),
      'Content-Type': 'multipart/form-data',
    },
    data,
  };

  return axios(config);
};

export const uploadFile = (data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BEEM_API_URL}attachments/import`,
    headers: {
      Authorization: getAccessToken(),
      'Content-Type': 'multipart/form-data',
    },
    data,
  };

  return axios(config);
};

export const uploadMsgTemplateImg = (data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_BEEM_API_URL}message-templates`,
    headers: {
      Authorization: getAccessToken(),
      'Content-Type': 'multipart/form-data',
    },
    data,
  };

  return axios(config);
};

export const subscribeToken = (data) => {
  return post('/subscribe', data);
};

export const deleteCatalogImages = (data) => {
  return post('/attachments/delete', data);
};

export const downloads3image = (params) => {
  return get('/attachments/download', params);
};

export const getAssignedTags = (params) => {
  return get('/contact-tags/assigned-tags', params);
};

export const postTagsAssigned = (data) => {
  return post('/contact-tags/assigned-tags', data);
};

export const deleteTagsAssigned = (data) => {
  const httpClient = createHttpClient();
  return wrapRequest(
    httpClient.delete('/contact-tags/assigned-tags', {
      data,
    })
  );
};

export const getTags = (params) => {
  return get('/contact-tags/tags', params);
};

/**
 * @function
 * @name generateContactExport
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const generateContactExport = (data) => {
  return post(`${process.env.REACT_APP_CONTACT_URL}/contacts/export`, data);
};

/**
 * @function
 * @name bulkDeleteContacts
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */
export const bulkDeleteContacts = (data) => {
  return axios.delete(
    `${process.env.REACT_APP_CONTACT_URL}/contacts/bulkContacts`,
    {
      data,
      headers: HEADERS,
    }
  );
};

/**
 * @function
 * @name bulkDeleteAddressBooks
 * @description Generate Exports
 * @param {object} data Export filters params
 * @returns {Promise} Axios API Call Promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const bulkDeleteAddressBooks = (data) => {
  return axios.delete(
    `${process.env.REACT_APP_CONTACT_URL}/contacts/bulkAddressbooks`,
    {
      data,
      headers: HEADERS,
    }
  );
};

/**
 * @function
 * @name deleteContacts
 * @description Delete existing network route in admin
 * @param {string|number} data params Network route unique identification
 * @param {string} params Delete contacts
 * @returns {Promise} Axios API call promise
 * @version 0.1.0
 * @since 0.1.0
 */

export const deleteContacts = (data, params) => {
  return axios.delete(
    `${process.env.REACT_APP_BEEM_API_URL}/contacts/${data}`,
    {
      params,
      headers: HEADERS,
    }
  );
};

export const postPayment = (params) => {
  return post('/bpay', params);
};

export const getPaymentLinkRoutes = (params) => {
  return get('/bpay', params);
};

export const getDPOPaymentMethods = (params) => {
  return post('/bpay/dpo-payment-methods', params);
};

export const getBPayClientRoutes = (params) => {
  return get(`${process.env.REACT_APP_BPAY_URL}/push-client-routes`, params);
};

export const getReportTags = (params) => {
  return get(`${process.env.REACT_APP_TAGGING_URL}/reports/tags`, params);
};

export const getTotalTags = (params) => {
  return get(`${process.env.REACT_APP_TAGGING_URL}/reports/total-tags`, params);
};

export const getRecentTagCounts = (params) => {
  return get(
    `${process.env.REACT_APP_TAGGING_URL}/reports/recent-tags-count`,
    params
  );
};
export const postTags = (params) => {
  return post(`${process.env.REACT_APP_TAGGING_URL}/tags`, params);
};

export const putTags = (data) => {
  return patch(`${process.env.REACT_APP_TAGGING_URL}/tags/${data.id}`, data);
};

export const deleteTags = (data) => {
  return del(`${process.env.REACT_APP_TAGGING_URL}/tags/${data.id}`);
};

export const getMojaBundles = (params) => {
  return get('/chat-bundles', params);
};

export const postMojaBundles = (data) => {
  return post('/chat-bundles', data);
};
export const patchMojaBundles = (data) => {
  return patch(`/chat-bundles/${data.id}`, data);
};
export const deleteMojaBundles = (data) => {
  return del(`/chat-bundles/${data.id}`);
};

export const getMessageTemplate = (data) => {
  return get('/message-templates', data);
};

export const postMessageTemplate = (data) => {
  return post('/message-templates', data);
};

export const putMessageTemplate = (id, data) => {
  return put(`/message-templates/${id}`, data);
};

export const deleteMessageTemplate = (id) => {
  return del(`/message-templates/${id}`);
};

export const sendMessageTemplate = (data) => {
  return post('/chat-send', data);
};

/**
 * @function
 * @name postOAuth
 * @description Update existing OAuth User
 * @param {object} data PUT data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const postOAuth = (data) => {
  return post('/admin-users', data);
};

/**
 * @function
 * @name putOAuth
 * @description Update existing OAuth User
 * @param {object} data PUT data
 * @returns {Promise} Axios Promise response
 * @version 0.1.0
 * @since 0.1.0
 */
export const putOAuth = (data) => {
  return put('/', data);
};

export const sendSMS = (data) => {
  return post('/sms/send', data);
};
export const sendGroupSMS = (data) => {
  return post('/sms/group-send', data);
};

export const searchContacts = (data) => {
  return get('/search-active-contact', data);
};
export const searchInactiveText = (data) => {
  return get('/inactive-search', data);
};

export const updateUsernameInRedis = (data) => {
  return put('/update-username', data);
};

export const addChatSettings = (data) => {
  return post('/chat-settings', data);
};

export const updateChatSettings = (data) => {
  return put('/chat-settings', data);
};

export const getChatSettings = (data) => {
  return get('/chat-settings', data);
};

export const updateKanbanCardTag = (data) => {
  return post('/update-kanban-card', data);
};

export const updateTwoWayCallbackUrl = (data) => {
  return axios.patch(
    `${process.env.REACT_APP_TWO_WAY_URL}/number/user/update/callback/${data.user_id}`,
    {
      ...data,
    },
    { headers: HEADERS }
  );
};

export const getAddressBooksDetails = (id, data) => {
  return get(
    `${process.env.REACT_APP_CONTACT_URL}/contacts/getContactDetailsByMobNo/${id}`,
    data
  );
};

export const getInboundOutboundSummary = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/inbound-outbound-summary`,
    params
  );
};

export const getInboundOutboundDetails = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/inbound-outbound-details`,
    params
  );
};

export const getAverageResponseDetails = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/average-response-time`,
    params
  );
};

export const getAverageResolutionDetails = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/average-resolution-time`,
    params
  );
};

export const getOpenCloseSessionDetails = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/open-close-session-details`,
    params
  );
};

export const getOpenCloseSessionSummary = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/open-close-session-summary`,
    params
  );
};

export const getTop5Agents = (params) => {
  return get(
    `${process.env.REACT_APP_REPORTS_API_URL}/workflow/top-agents`,
    params
  );
};

export const getZohoSurveyUsers = (params) => {
  return get('/zoho-survey', params);
};
export const updateZohoSurveyUser = (data) => {
  return put('/zoho-survey', data);
};
