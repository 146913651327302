/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { getChatBots } from './client/client';

export const BotSettingContext = React.createContext({});

export const BotSettingProvider = ({ children }) => {
  const [shouldReload, setShouldReload] = useState(0);
  const [botSetting, setBotSetting] = useState(0);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    return getChatBots()
      .then((response) => {
        if (response) {
          setBotSetting(response.data.length);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [shouldReload]);

  return (
    <BotSettingContext.Provider
      value={{
        botSetting,
        refresh: () => setShouldReload(shouldReload + 1),
        isLoading,
      }}
    >
      {children}
    </BotSettingContext.Provider>
  );
};

export const useBotSetting = () => useContext(BotSettingContext);

BotSettingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

BotSettingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
