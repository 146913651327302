import React, { useState, useContext, useCallback, useEffect } from 'react';
import { getChatSettings, getChatBots } from './client';

export const ChatContext = React.createContext();

const defaultChat = {
  show: false,
  mob_no: '',
  can_chat: false,
  user: [],
};

export const ChatProvider = ({ children, chat }) => {
  const [currentChat, setCurrentChat] = useState(chat || defaultChat);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingEmpty, setLoadingEmpty] = useState(false);
  const [kanban, setKanban] = useState(false);
  const [hasChannel, setHasChannel] = useState(false);
  const [showChannel, setChannelModal] = useState(false);
  const [chatSetting, setChatSetting] = useState(false);

  const saveChat = (values) => {
    setLoading(true);
    setCurrentChat(values);
    setLoading(false);
  };

  const refresh = useCallback(
    () => setShouldRefresh(!shouldRefresh),
    [shouldRefresh]
  );

  useEffect(() => {
    setLoading(true);
    getChatSettings()
      .then((result) => {
        if (result && result.data) {
          setKanban(result.data[0].view_type);
          setChatSetting(result.data[0].user_id);

          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoadingEmpty(true);
    getChatBots()
      .then((result) => {
        if (result && result.data.length > 0) {
          setHasChannel(true);
          setLoadingEmpty(false);
        }
        setLoadingEmpty(false);
      })
      .catch(() => {
        setLoadingEmpty(false);
      });
  }, []);

  return (
    <ChatContext.Provider
      value={{
        chat: currentChat,
        saveChat,
        refresh,
        isLoading,
        setLoading,
        setKanban,
        kanban,
        hasChannel,
        setChannelModal,
        showChannel,
        setLoadingEmpty,
        isLoadingEmpty,
        setChatSetting,
        chatSetting,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const chatContext = () => useContext(ChatContext);
