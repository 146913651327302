import { merge, forEach } from 'lodash';
import { createHttpActionsFor } from './client';

/* API resources/endpoints that are accessible to the web */

const API_RESOURCES = [
  {
    name: 'transfer',
  },
  {
    name: 'bot',
  },
  {
    name: 'user',
  },
  {
    name: 'admin',
  },
  {
    name: 'plan',
  },
  {
    name: 'transactions',
  },
  {
    name: 'credit',
  },
  { name: 'contact' },
  {
    name: 'crm',
  },
  {
    name: 'credit-scheme',
  },
  {
    name: 'sender-name',
  },
  { name: 'sms-template' },
  { name: 'purchase' },
  { name: 'payment' },
  {
    name: 'vendor',
  },
  {
    name: 'agent',
  },
];

/* http actions exposed by this client */
export const httpActions = {};

export * from './client';

forEach(API_RESOURCES, (resource) => {
  const resourceHttpActions = createHttpActionsFor(resource);
  merge(httpActions, resourceHttpActions);
});
