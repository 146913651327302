import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useToasts } from 'react-toast-notifications';
import { LocaleContext, ConfigsContext, Component } from './configs';
import { getLocale, getBalance, getManagerBalance } from './client';
import { isAgent } from './util';

// declarations
const defaultLocale = getLocale() || 'en';

// eslint-disable-next-line react/prop-types
export const ComponentProvider = ({ children, ...rest }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Component.Provider value={{ toggle, setToggle, ...rest }}>
      {children}
    </Component.Provider>
  );
};
export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale: (newLocale) => setLocale(newLocale),
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const ConfigProvider = ({ children }) => {
  const { addToast } = useToasts();
  const [credits, setCredits] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setLoading(true);
    if (isAgent()) {
      getManagerBalance()
        .then((response) => {
          setLoading(false);
          setCredits(Math.round(response.data.data.credit_bal));
          setBalance(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          addToast(error.message, { appearance: 'error' });
        });
    } else {
      getBalance({ app_name: process.env.REACT_APP_APP_NAME })
        .then((response) => {
          setLoading(false);
          setCredits(Math.round(response.data.credit_bal));
          setBalance(response.data);
        })
        .catch((error) => {
          setLoading(false);
          addToast(error.message, { appearance: 'error' });
        });
    }
  }, [addToast, shouldReload]);

  return (
    <ConfigsContext.Provider
      value={{
        credits,
        balance,
        isLoading,
        refreshBalance: () => setShouldReload(shouldReload + 1),
      }}
    >
      {children}
    </ConfigsContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
