/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { isValidUser } from '../../client';

/**
 * @function
 * @name AuthPage
 * @description Page for authentication for login page
 *
 * @returns {null} Nothing
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const AuthPage = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  // eslint-disable-next-line
  const url = process.env.REACT_APP_AUTH_REDIRECT_URL;
  // eslint-disable-next-line no-undef
  window.location.replace(`${url}?client_id=${clientId}`);
  return null;
};

const SecureRoute = ({ component: Component, data, location, ...rest }) => {
  const isAuthenticated = isValidUser();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} {...data} /> : <AuthPage />
      }
    />
  );
};

export default withRouter(SecureRoute);
