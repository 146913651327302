/* eslint-disable no-undef */
import { createBrowserHistory } from 'history';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import { IntlProvider } from 'react-intl';
import { HashRouter, Redirect, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import SecureRoute from './app/components/SecureRoute';
import tracker from './app/GA-Tracking';
import './assets/font-icons/style.css';
import { BotSettingProvider } from './bot-setting.provider';
import { getThemeColor, saveToken } from './client';
import { LocaleContext } from './configs';
import messages from './localeData.json';
import './main.scss';
import './assets/css/main-chat.scss';
import './assets/css/dashboard.scss';
import './assets/css/settings.scss';
import { ConfigProvider, LocaleProvider } from './providers';
import { ChatProvider } from './chat-setting.provider';
import { configurePushSubscription } from './util';
import initFacebookSdk from './app/Channels/facebookinit';

const Dashboard = lazy(() => import('./app/Dashboard'));

const history = createBrowserHistory();
history.listen(() => {
  tracker.setField(window.location.hash);
  tracker.sendPageView(window.location.hash);
});

// Make sure sw is supported
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => console.log('Service Worker Registered'))
      .catch((err) =>
        console.log(`Service Worker Regsistration failed: ${err}`)
      );
  });
}

/**
 * @function
 * @name App
 * @description Application Entry point
 *
 * @returns {object} React component
 *
 *
 * @version 0.1.0
 * @since 0.1.0
 */

const App = () => {
  const [isLoading, setLoading] = useState(false);

  const parsed = queryString.parse(window.location.search); // eslint-disable-line

  if ('Notification' in window) {
    try {
      if (Notification.permission === 'granted') {
        configurePushSubscription();
      }
    } catch (error) {
      console.log('Notification permission failed', error);
    }
  }

  useEffect(() => {
    if (!isEmpty(parsed.token)) {
      setLoading(true);
      saveToken(parsed.token, parsed.client_id)
        .then(() => {
          setLoading(false);
          window.location.replace(`/#!/dashboard?token=${parsed.token}`); // eslint-disable-line
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  initFacebookSdk();

  return isLoading ? (
    <div className="d-flex align-items-center justify-content-center my-2">
      <Spinner as="span" animation="border" role="status" aria-hidden="false" />
    </div>
  ) : (
    <Suspense
      fallback={
        <div className="d-flex align-items-center justify-content-center my-2">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="false"
          />
        </div>
      }
    >
      <ThemeProvider theme={{ color: getThemeColor() }}>
        <LocaleProvider>
          <LocaleContext.Consumer>
            {(context) => (
              <IntlProvider
                locale={context.locale}
                key={context.locale}
                messages={messages[context.locale]}
              >
                <ToastProvider placement="top-center" autoDismiss>
                  <ConfigProvider>
                    <BotSettingProvider>
                      <ChatProvider>
                        <HashRouter hashType="hashbang" history={history}>
                          <Switch>
                            <SecureRoute
                              path="/dashboard"
                              component={Dashboard}
                            />
                            <Redirect to="/dashboard" />
                          </Switch>
                        </HashRouter>
                      </ChatProvider>
                    </BotSettingProvider>
                  </ConfigProvider>
                </ToastProvider>
              </IntlProvider>
            )}
          </LocaleContext.Consumer>
        </LocaleProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
