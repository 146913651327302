import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://9d4fa80b657e455786da3e85219d9b4f@o430675.ingest.sentry.io/5917216',
//     debug: true,
//     environment: `${process.env.NODE_ENV}`,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//     ignoreErrors: [
//       'Please login to access resources',
//       'ChunkLoadError',
//       'Loading chunk 402 failed.',
//       'Loading chunk 535 failed.',
//       'Request aborted',
//       'Network Error',
//       'timeout exceeded',
//       'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
//       'AbortError: The play() request was interrupted by a new load request',
//       'NotAllowedError: play() failed',
//     ],
//   });

//   // Additional Data (Custom data)
//   Sentry.configureScope((scope) => {
//     scope.setTag('environment', `${process.env.NODE_ENV}`);
//   });
// }

const render = (Component) =>
  ReactDOM.render(<Component />, document.getElementById('root')); // eslint-disable-line

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line global-require
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
